import React, { useState } from 'react';
import { Button, Container, TextField, createTheme, ThemeProvider, Checkbox, FormControlLabel } from '@mui/material';
import QRCode from 'qrcode'

const placeholderImageURL = "https://placehold.co/600?text=Your+QR+Code+will+Appear+Here&font=montserrat";

// allow broken link overwrite

const theme = createTheme({
  typography: {
    fontFamily: [
      "montserrat",
      "sans-serif"
    ].join(','),
  },
});

const isURLValid = async (url: string) => {
    const proxyUrl = 'https://corsproxy.io/?';
    const finalUrl = proxyUrl + url;
  
    return fetch(finalUrl, { method: 'HEAD' })
      .then(response => response.ok)
      .catch(error => {
        console.error(error);
        return false;
      });
}

function App() {
  const [imageURL, setImageURL] = useState(placeholderImageURL);
  const [linkURL, setLinkURL] = useState("");
  const [fileName, setFileName] = useState("QR_code");
  const [size, setSize] = useState(512);

  const [hasValidationBeenDone, setHasValidationBeenDone] = useState(false);
  const [ isLinkValid, setIsLinkValid ] = useState(false);
  const [ isLinkValidationIgnored, setIsLinkValidationIgnored ] = useState(false);

  const isDefaultImage = imageURL === placeholderImageURL;

  const clearImage = () => {
    setImageURL(placeholderImageURL);
  }

  const generateQR = async (link: string, size: number) => {
    QRCode.toDataURL(link, { errorCorrectionLevel: 'H', width: size }).then(setImageURL);
  }

  const downloadImage = () => {
    const downloadLink = document.createElement("a");
    downloadLink.href = imageURL;
    downloadLink.download = fileName;
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  }

  const validateLink = (value: string) => {

    isURLValid(value)
      .then((isValid) => {
        setHasValidationBeenDone(true);
        setIsLinkValid(isValid);
      })

  }

  console.log("hasValidationBeenDone: ", hasValidationBeenDone);
  console.log("isLinkValid: ", isLinkValid);
  console.log("IGNORE: ", isLinkValidationIgnored)

  return (
    <ThemeProvider theme={theme}>
      <div className="App">
        <header className="App-header">
          <Container sx={{ display: "flex", alignItems: "center", flexDirection: "column" }}>
            <img alt="Company Logo" src={"./logo.svg"} />
            <h1>QR Code Generator</h1>
          </Container>
        </header>
        <Container sx={{ display: "flex", justifyContent: "center", alignItems: "center", width: "100%" }}>
          <Container sx={{ width: "50%" }}>
            <TextField
              label="Link URL"
              variant="outlined"
              sx={{ width: "100%", marginBottom: "1rem" }}
              value={linkURL}
              onChange={(e) => {
                setLinkURL(e.target.value);
                setHasValidationBeenDone(false);
                setIsLinkValidationIgnored(false);
              }}
              onBlur={(e) => validateLink(e.target.value)}
              color={
                hasValidationBeenDone ? 
                  isLinkValid ?
                    "success"
                    : "error"
                  : "primary"
              }
              focused
            />
            <TextField label="QR Code Size" variant="outlined" type="number" sx={{ width: "100%", marginBottom: "1rem" }} value={size} onChange={(e) => setSize(Number.parseInt(e.target.value))} />
            <Container sx={{ display: "flex", justifyContent: "center", alignItems: "center", width: "100%" }}>
              <Button variant="contained" disabled={!isLinkValidationIgnored && ( !hasValidationBeenDone || (hasValidationBeenDone && !isLinkValid) )} onClick={() => generateQR(linkURL, size)} sx={{marginRight: "1rem"}}>Generate QR Code</Button>
              {hasValidationBeenDone && !isLinkValid &&
                <FormControlLabel control={<Checkbox value={isLinkValidationIgnored} onChange={(e) => setIsLinkValidationIgnored(e.target.checked)} />} label="Ignore Invalid Link Validation" />
              }
            </Container>
          </Container>
          <Container sx={{ width: "50%" }}>
            <img alt="QR Code Preview" src={imageURL} style={{ width: "100%" }} />
            {!isDefaultImage &&
              <>
                <Container sx={{ display: "flex", width: "100%", alignItems: "center" }}>
                  <TextField label="Output File Name" variant="standard" sx={{ width: "100%", marginBottom: "1rem" }} value={fileName} onChange={(e) => setFileName(e.target.value)} /> <span style={{ fontSize: "1.125rem" }}>.png</span>
                </Container>
                <Container sx={{ display: "flex", width: "100%" }}>

                  <Button variant="contained" sx={{ marginRight: "1rem", width: "50%" }} onClick={downloadImage}>Download</Button>
                  <Button variant="outlined" sx={{ width: "50%" }} onClick={clearImage}>Clear</Button>
                </Container>
              </>
            }
          </Container>
        </Container>
      </div>
    </ThemeProvider>
  );
}

export default App;